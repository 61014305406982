import React from 'react';
import { Typography, Grid as MuiGrid } from '@material-ui/core';
import styled from 'styled-components';
import Section from './section';
import Price from '../cards/priceCard';

const PricingSection = styled(Section)`
  padding: 0 2rem 4rem 2rem;
  width: 100%;
  ${props => props.theme.breakpoints.up('lg')} {
    padding: 0 0 4rem 0;
  }
`;

const Grid = styled(MuiGrid)`
  padding: 0.5rem;
`;

const Pricing = ({ prices }) => (
  <PricingSection maxWidth="lg">
    <MuiGrid container>
      <MuiGrid item xs={12} component={Typography} variant="h2">
        {prices.heading == null ? 'Pricing' : prices.heading}
      </MuiGrid>
      <Grid item xs={12} component={Typography} variant="h5">
        {prices.subheading == null ? '' : prices.subheading}
      </Grid>
      <MuiGrid item xs={12} container>
        {prices.servicePriceList.length ? (
          prices.servicePriceList.map((price, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <Price price={price} serviceName={prices.heading} />
            </Grid>
          ))
        ) : (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Price />
          </Grid>
        )}
      </MuiGrid>
    </MuiGrid>
  </PricingSection>
);
export default Pricing;
