import React from 'react';
import styled from 'styled-components';
import { Typography, Grid } from '@material-ui/core';

const HeaderText = styled(Typography)`
  /* font-size: 1.25rem; */
  & span {
    font-size: 1rem;
  }
`;

const CardContainer = styled.div`
  width: 100%;
  border-radius: 10px;
  background: ${props =>
    props.color ? props.color : props.theme.palette.secondary.main};
`;

const CardHeader = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  /* Style */
  height: 80px;

  background: ${props =>
    props.color ? props.color : props.theme.palette.secondary.main};
  color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px 10px 0 0;

  h3 {
    font-size: 1rem;
    white-space: nowrap;
  }
  h2 {
    font-size: 3rem;
  }
`;

const DurationType = styled(Grid)`
  display: flex;
  flex-flow: column;
  padding-left: 0.5rem;
  * {
    line-height: 1.2;
    padding: 0;
    margin: 0;
  }
`;

const CardBody = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  background: rgba(255, 255, 255, 0.5);

  small {
    font-size: 0.75rem;
  }
`;

const PriceCard = ({ price }) => (
  <CardContainer color={price.backgroundColor}>
    <CardHeader item container justify="center" color={price.backgroundColor}>
      {price.duration ? (
        <Grid item xs>
          <HeaderText variant="h2" align="center">
            {price.duration && price.duration}
            <span> {price.durationType && price.durationType}</span>
          </HeaderText>
          {/* <Typography variant="body1" align="center"></Typography> */}
        </Grid>
      ) : (
        <Grid item xs>
          <Typography variant="h4" align="center">
            {price.durationType && price.durationType}
          </Typography>
        </Grid>
      )}
    </CardHeader>
    <CardBody>
      <Typography variant="h4">
        ${price.cost && price.cost.toFixed(2)}
        <small> plus HST</small>
      </Typography>
    </CardBody>
  </CardContainer>
);

export default PriceCard;
