import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout/layout';
import FAQs from '../components/sections/faqs';
import Request from '../components/sections/request';
import Quote from '../components/sections/quote';
import Services from '../components/sections/services';
import Pricing from '../components/sections/pricing';
import Body from '../components/sections/body';
import SEO from '../components/seo';

const Service = ({ data }) => {
  const page = data.sanityService;
  return (
    <Layout
      heroButton={page.requestFormToggle}
      heroHeading={page.heading == null ? 'Service' : page.heading}
      heroImage={page.image}
    >
      <SEO title={page.heading == null ? 'Service' : page.heading} />
      {/* Main Service Description */}
      {/* Body */}
      {page._rawDescription && (
        <Body
          id="description"
          heading={page.heading}
          subheading={page.subheading}
          content={page._rawDescription}
        />
      )}
      {/* Pricing Section */}
      {page.pricingSection.servicePriceList.length ? (
        <Pricing prices={page.pricingSection} />
      ) : (
        ''
      )}
      {/* Related Services Section */}
      {page.relatedServiceSection &&
        page.relatedServiceSection.serviceList.length > 0 && (
          <Services services={page.relatedServiceSection} />
        )}
      {/* Faqs */}
      {page.faqSection.faqList.length ? <FAQs faqs={page.faqSection} /> : ''}
      {page.requestFormToggle && <Request />}
      {page.quoteToggle && <Quote />}
    </Layout>
  );
};

export default Service;

export const query = graphql`
  query serviceQuery($id: String) {
    sanityService(id: { eq: $id }) {
      heading
      subheading
      _rawDescription
      pricingSection {
        heading
        subheading
        servicePriceList {
          cost
          duration
          durationType
          backgroundColor
        }
      }
      faqSection {
        heading
        subheading
        faqList {
          question
          answer
        }
      }
      relatedServiceSection {
        heading
        subheading
        serviceList {
          heading
          _rawDescription
          slug {
            current
          }
          pricingSection {
            heading
            subheading
            servicePriceList {
              cost
              duration
              durationType
              backgroundColor
            }
          }
          image {
            asset {
              fluid(maxWidth: 400) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
      image {
        asset {
          fluid(maxWidth: 2000) {
            ...GatsbySanityImageFluid
          }
        }
      }
      requestFormToggle
      quoteToggle
    }
  }
`;
